import React from 'react'
import * as NavStyles from './Nav.module.scss'

interface INavProps {
  absolute?: boolean
  homePage?: boolean
}

const Nav = ({ absolute, homePage }: INavProps) => {
  return (
    <div className={`${absolute ? NavStyles.TideAbsoluteWrapper : ''}`}>
      {homePage && (
        <div className={NavStyles.HeroText}>
          <span>Enter to Win</span>
          <span className={NavStyles.Span}>£10,000</span>
          {/* <span>for your business</span> */}
        </div>
      )}

      <div
        className={`${NavStyles.TideHeaderLogo} ${
          absolute ? NavStyles.TideAbsolute : ''
        }`}
      >
        <div className={NavStyles.MobileImage} />
        <img
          src={
            absolute
              ? 'http://dev.muddystilettos.co.uk/wp-content/uploads/2023/09/MS_She-Means-Business-Final-Logo_Tide_White_1_f.png'
              : 'http://dev.muddystilettos.co.uk/wp-content/uploads/2023/09/MS_She-Means-Business-Final-Logo_Tide_White_1_f.png'
          }
        />
      </div>
    </div>
  )
}

export default Nav
