// extracted by mini-css-extract-plugin
export var Dark = "Nav-module--Dark--f74d3";
export var HeaderLogo = "Nav-module--HeaderLogo--f7281";
export var HeroText = "Nav-module--HeroText--07a3a";
export var Light = "Nav-module--Light--a1adf";
export var Link = "Nav-module--Link--ba322";
export var MobileImage = "Nav-module--MobileImage--a6340";
export var Nav = "Nav-module--Nav--ce1c3";
export var Span = "Nav-module--Span--a0c02";
export var TideAbsolute = "Nav-module--TideAbsolute--c8e84";
export var TideAbsoluteWrapper = "Nav-module--TideAbsoluteWrapper--44e92";
export var TideHeaderLogo = "Nav-module--TideHeaderLogo--09d6d";