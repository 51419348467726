// import WhyGetInvolved from '../../components/BestSchoolsAwards/WhyGetInvolved/WhyGetInvolved'
// import Categories from '../../components/BestSchoolsAwards/Categories/Categories'
import BSAContent from '../../components/BestSchoolsAwards/Content/Content'
import BSAFooter from '../../components/BestSchoolsAwards/Footer/Footer'
import TideHeaderLogo from '../../components/BestSchoolsAwards/HeaderLogo/TideHeaderLogo'
import Nav from '../../components/BestSchoolsAwards/Nav/Nav'
import React from 'react'
import { Helmet } from 'react-helmet'
import * as ContentStyles from '../../components/BestSchoolsAwards/Content/Content.module.scss'
// import TestimonialSlider from '../../components/BestSchoolsAwards/TestimonialSlider/TestimonialSlider'

const WIBHowItWorks = () => {
  return (
    <>
      <Helmet>
        <title>She Means Business | Muddy Stilettos</title>
      </Helmet>
      <Nav site="tide" theme="dark" />
      <TideHeaderLogo />
      <BSAContent>
        <h1>FAQs</h1>
        <p className={ContentStyles.FAQSecton}>
          <strong>How does the competition work?  </strong>
        </p>
        <p>
          The SHE MEANS BUSINESS Award is simple to enter, with a female-led
          business in line to win £10,000. Please visit the How It Works page
          for more information [link to how it works]
        </p>
        <p className={ContentStyles.FAQSecton}>
          <strong>Who can enter?</strong> 
        </p>
        <p>
          Women who own or co-own an existing business, or women who have a
          business idea that is yet to launch and are looking for that extra
          push to get it started. If a co-owner, you need to be managing the
          business. If you co-own with male partners, you need to own a minimum
          of 50% of the business. 
        </p>
        <p className={ContentStyles.FAQSecton}>
          <strong>Can I enter if I already have a Tide account?</strong>
        </p>
        <p>
          No. The SHE MEANS BUSINESS Award is eligible to those who currently do
          not have an existing Tide Business Account. So you need to open a Tide
          account to enter the competition and we hope you continue to use it,
          but it’s up to you.
        </p>
        <p className={ContentStyles.FAQSecton}>
          <strong>How will it be judged?</strong> 
        </p>
        <p>
          Entries will be reviewed by an expert judging panel from Muddy
          Stilettos that will choose three finalists (see below for more details
          what happens if you are a finalist). Muddy Stilettos’ Founder and
          Editor-in-Chief Hero Brown is an entrepreneur who started Muddy
          Stilettos 10 years ago from her kitchen table and it has grown to
          become the UK’s biggest luxury lifestyle platform for women outside
          London. 
        </p>
        <p className={ContentStyles.FAQSecton}>
          <strong>What happens if I’m a finalist?</strong>
        </p>
        <p>
          If you’re one of the three finalists, you’ll need to prepare a
          three-minute informal pitch to be presented to a small judging panel
          consisting of Muddy Stilettos experts and a Tide representative
          between 1-5pm, 06 December at Thames Lido, Reading, Berks.
        </p>
        <p>
          As one of the three finalists, if you are an existing business you
          will be asked to provide a financial statement to prove you were in
          net profit for the 2022-23 financial year. 
        </p>
        <p>
          New business start-ups who have not yet traded for one year will need
          to show a bank statement to prove they are able to sustain their
          business forward for 12 months in 2024
        </p>
        <p className={ContentStyles.FAQSecton}>
          <strong>What’s the deadline for entries?</strong>
        </p>
        <p>
          Competition entries must be completed by 2pm on 9 November 2023. Three
          finalists will be notified on 29 November 2023.
        </p>
        <p className={ContentStyles.FAQSecton}>
          <strong>When will the winners be announced? </strong>
        </p>
        <p>12 December 2023</p>
        <p className={ContentStyles.FAQSection}>
          <strong>Will there be publicity about the competition?</strong>
        </p>
        <p>
          Yes! If you win, you’ll be featured on Muddy Stilettos, in the local
          press and also across some of Tide’s channels.
        </p>
        <p className={ContentStyles.FAQSecton}>
          <strong>What do I win?</strong>
        </p>
        <p>
          £10,000 cash for your business, delivered by Muddy Stilettos on 30
          December 2023. 
        </p>
      </BSAContent>
      {/* <WhyGetInvolved /> */}
      <BSAFooter />
    </>
  )
}

export default WIBHowItWorks
